:root {
  overflow: hidden;
  height: 100%;
}

body{
  position: relative;
  height: 100%;
}

.loading_container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.first_loading_gif{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
  animation-name: slide;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: 3s;
  z-index: 3;
}

.mobile_first_loading_gif{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: 3s;
  z-index: 3;
}

.main_content{
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.left_black_box{
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  width:30%;
  aspect-ratio: 5 / 8;
  z-index: 2;
  background-color: rgb(8, 7, 2);
  border-right: 0.1vw solid #fae4f9;
}

.right_black_box{
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  width:30%;
  aspect-ratio: 5 / 8;
  z-index: 2;
  background-color: rgb(8, 7, 2);
  border-left: 0.1vw solid #fae4f9;
}

.menu_box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:30%;
  aspect-ratio: 5 / 8;
  border: 0.1vw solid #fae4f9;

}

.mobile_left_black_box{
  position: absolute;
  top: 50%;
  left: -30%;
  transform: translate(-50%, -50%);
  width:80%;
  aspect-ratio: 5 / 8;
  z-index: 2;
  background-color: rgb(8, 7, 2);
  border-right: 0.1vw solid #fae4f9;
}

.mobile_right_black_box{
  position: absolute;
  top: 50%;
  left: 130%;
  transform: translate(-50%, -50%);
  width:80%;
  aspect-ratio: 5 / 8;
  z-index: 2;
  background-color: rgb(8, 7, 2);
  border-left: 0.1vw solid #fae4f9;
}

.mobile_menu_box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:80%;
  aspect-ratio: 5 / 8;
  border: 0.1vw solid #fae4f9;

}


.man_1_for_menu{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 - 40%);
  left: 12%;
  transform: translate(-50%);
  width: 20%;
}

.man_2_for_menu{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 - 40%);
  left: 88%;
  transform: translate(-50%);
  width: 20%;
}

.border_right{
  position: absolute;
  border-right:0.1vw solid #fae4f9;
  top: calc(50% - (5/8)*25%/2 - 10%);
  left: 50%;
  height: 65%;
}

.my_name{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 1.8vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 31%);
  left: 50%;
  transform: translate(-50%);
  border-bottom:0.1vw solid #fae4f9;
  padding-bottom:0.2vw;
}

.mobile_my_name{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 4.5vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 31%);
  left: 50%;
  transform: translate(-50%);
  border-bottom:0.1vw solid #fae4f9;
  padding-bottom:0.2vw;
}

.social_media{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 1.2vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 25%;
  transform: translate(-50%);
}

.mobile_social_media{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.5vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 25%;
  transform: translate(-50%);
}

.github_logo{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 - 4%);
  left: 12%;
  transform: translate(-50%);
  width: 8%;
}

.github_my_account{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 0.9vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 2.5%);
  left: 30%;
  transform: translate(-50%);
  text-decoration:none;
}
.mobile_github_my_account{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.3vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 2.5%);
  left: 30%;
  transform: translate(-50%);
  text-decoration:none;
}

.linkedin_logo{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 + 7%);
  left: 12.5%;
  transform: translate(-50%);
  width: 8%;
}

.linkedin_my_account{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 0.9vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 + 8%);
  left: 30%;
  transform: translate(-50%);
  text-decoration:none;
}

.mobile_linkedin_my_account{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.3vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 + 8%);
  left: 30%;
  transform: translate(-50%);
  text-decoration:none;
}

.instagram_logo{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 + 17%);
  left: 12%;
  transform: translate(-50%);
  width: 8%;
}

.instagram_my_account{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 0.9vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 + 18.5%);
  left: 34%;
  transform: translate(-50%);
  text-decoration:none;
}

.mobile_instagram_my_account{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.3vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 + 18.5%);
  left: 34%;
  transform: translate(-50%);
  text-decoration:none;
}

.work{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 1.2vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 75%;
  transform: translate(-50%);
}

.mobile_work{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.5vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 75%;
  transform: translate(-50%);
}

.oss_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 0.9vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 3%);
  left: 75%;
  transform: translate(-50%);
  text-decoration:none;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.mobile_oss_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.3vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 3%);
  left: 75%;
  transform: translate(-50%);
  text-decoration:none;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.art_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 0.9vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 + 7.5%);
  left: 75%;
  transform: translate(-50%);
  text-decoration:none;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.mobile_art_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 2.3vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 + 7.5%);
  left: 75%;
  transform: translate(-50%);
  text-decoration:none;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.menu_page{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  aspect-ratio: 5 / 8;
}


.slide_left{
  animation-name: slideOut_to_left;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;

}

.oss_page{
  position: absolute;
  top: 50%;
  left: 150%;
  transform: translate(-50%, -50%);
  width:100%;
  aspect-ratio: 5 / 8;

}

.oss_main_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 1.8vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 31%);
  left: 50%;
  transform: translate(-50%);
  border-bottom:0.1vw solid #fae4f9;
  padding-bottom:0.2vw;

}

.mobile_oss_main_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 4.5vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 31%);
  left: 50%;
  transform: translate(-50%);
  border-bottom:0.1vw solid #fae4f9;
  padding-bottom:0.2vw;

}

.colormkdir_logo{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 25%;
  transform: translate(-50%);
  width: 35%;
}

.colormkdir_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 1.1vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 19%);
  left: 50%;
  transform: translate(-50%);
  text-decoration:none;
}

.mobile_colormkdir_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 3vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 19%);
  left: 50%;
  transform: translate(-50%);
  text-decoration:none;
}

.colormkdir_detail_txt{
  position: absolute;
  white-space: normal;
  font-family: Ubuntu;
  font-size: 0.8vw;
  color: #fae4f9;
  width: 50%;
  padding: 0;
  margin: 0;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 72.5%;
  transform: translate(-50%);
}

.mobile_colormkdir_detail_txt{
  position: absolute;
  white-space: normal;
  font-family: Ubuntu;
  /* font-size: 0.8vw; */
  font-size: 2.3vw;
  color: #fae4f9;
  width: 50%;
  padding: 0;
  margin: 0;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 72.5%;
  transform: translate(-50%);
}

.dli-chevron-round-left {
  position: absolute;
  width: 4%;
  aspect-ratio: 1 / 1;
  border-top: solid 0.1vw #fae4f9;
  border-right: solid 0.1vw #fae4f9;
  border-left: none;
  border-bottom: none;
  left: 10%;
  top: calc(50% - (5/8)*25%/2 - 36%);
  transform: rotate(-135deg);
  text-decoration:none;
  /* border: none; */
  outline: none;
  background: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.slide_right{
  animation-name: slideOut_to_right;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
}

.art_page{
  position: absolute;
  top: 50%;
  left: 150%;
  transform: translate(-50%, -50%);
  width:100%;
  aspect-ratio: 5 / 8;

}

.art_main_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 1.8vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 31%);
  left: 50%;
  transform: translate(-50%);
  border-bottom:0.1vw solid #fae4f9;
  padding-bottom:0.2vw;

}

.mobile_art_main_txt{
  position: absolute;
  white-space: nowrap;
  font-family: Ubuntu;
  font-size: 4.5vw;
  color: #fae4f9;
  padding: 0;
  top: calc(50% - (5/8)*25%/2 - 31%);
  left: 50%;
  transform: translate(-50%);
  border-bottom:0.1vw solid #fae4f9;
  padding-bottom:0.2vw;

}

.search_light{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 25%;
  transform: translate(-50%);
  width: 35%;
}

.music_life{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 - 15%);
  left: 75%;
  transform: translate(-50%);
  width: 35%;
}

.rainy_day{
  position: absolute;
  top: calc(50% - (5/8)*25%/2 + 15%);
  left: 25%;
  transform: translate(-50%);
  width: 35%;
}




@keyframes slide {
  0% {
  transform: translate(-50%, -50%);
  }

  100% {
  transform: translate(-220%, -50%);
  }
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideOut_to_left {
	from {
		transform: translate(-50%, -50%);
	}
	to {
		transform: translate(-150%, -50%);
	}
}

@keyframes slideOut_to_right {
	from {
		transform: translate(-150%, -50%);
	}
	to {
		transform: translate(-50%, -50%);
	}
}

